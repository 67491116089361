import React, { useCallback } from 'react';
// Components
import { Controller } from 'react-hook-form';
// Types
import type { FieldValues } from 'react-hook-form';

// styles
import css from './index.css';

import type { DateTimePickerProps, UseDateTimePickerProps } from '../../../DateTimePicker/_types';
import type { FormOptions } from '../../_types';

import DateTimePicker from '../../../DateTimePicker';
// Hooks
import useDateTimePicker from '../../../DateTimePicker/useDateTimePicker';
import Text from '../../../Text';

export type FormDatePickerProps<T extends FieldValues = FieldValues> = Omit<
  UseDateTimePickerProps,
  'onBlur' | 'onChange' | 'defaultValues' | 'error' | 'timePickerProps'
> & FormOptions<T> & {
  wrapperProps?: DateTimePickerProps['wrapperProps'];
};

const FormDatePicker = <T extends FieldValues = FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  ...dateTimePickerProps
}: FormDatePickerProps<T>) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue={defaultValue}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
      const { headerProps, inputProps, pickerProps, wrapperProps } = dateTimePickerProps;
      const dateValue = value ? new Date(value) : value;
      // setSelected will trigger when resetting the date
      const setSelected = (newValue: Date | null) => {
        if (!newValue) {
          inputProps?.setSelected?.(null);
          onChange('');
          return;
        }
        inputProps?.setSelected?.(newValue);
        onChange(newValue);
      };

      const [, { pickerProps: usePickerProps, ...timePickerProps }] =
        useDateTimePicker({
          initialDate: dateValue,
          timePickerProps: {
            selected: dateValue ?? null,
          },
          headerProps,
          inputProps: {
            ...inputProps,
            setSelected,
          },
          pickerProps: {
            ...pickerProps,
            onBlur,
            // we need to update the selected value because the date picker will not update the value if the date is the same
            selected: dateValue,
            value: value ?? '',
          },
        });

      const handleOnChange = useCallback(
        (dateTime: any) => {
          usePickerProps.onChange(dateTime);
          onChange(dateTime);
        },
        [onChange, usePickerProps.onChange]
      );

      return (
        <>
          <DateTimePicker
            pickerProps={{
              ...usePickerProps,
              onChange: handleOnChange,
            }}
            {...timePickerProps}
            wrapperProps={wrapperProps}
          />
          {error && (
            <Text size="small" className={css.error}>
              {error.message}
            </Text>
          )}
        </>
      );
    }}
  />
);

export default FormDatePicker;
