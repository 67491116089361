function ConnectionsIllustration(props: SVGProps): JSX.Element {
  const { title = 'Connections', ...restProps } = props;

  return (
    <svg
      viewBox="0 0 196 193"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
      fill="none"
    >
      <title>{title}</title>
      <path
        d="M150.281 148.42H181.917C185.521 148.42 188.442 145.499 188.442 141.895C188.442 138.29 185.521 135.37 181.917 135.37H171.717C168.112 135.37 165.192 132.449 165.192 128.845V125.333"
        stroke="#A2A4A8"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M47.281 40.3345H15.4154C11.8109 40.3345 8.89038 43.255 8.89038 46.8594C8.89038 50.4639 11.8109 53.3844 15.4154 53.3844H25.6154C29.2198 53.3844 32.1403 56.3049 32.1403 59.9094V63.4216"
        stroke="#A2A4A8"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M150.044 186.717V186.785L150.053 186.852C150.395 189.368 148.362 191.51 145.686 191.51H51.796C49.127 191.51 47.0693 189.367 47.3455 186.825L47.3513 186.772V186.717V2.00006H150.044V186.717Z"
        stroke="inherit"
        strokeWidth="2"
      />
      <path
        d="M47.4819 23.7831L149.998 23.7831V1.00003L47.4819 1.00003V23.7831Z"
        fill="inherit"
        stroke="inherit"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M115.814 12.3943H81.6638"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M189.377 63H137.623C134.673 63 132.281 65.3915 132.281 68.3416V120.096C132.281 123.046 134.673 125.438 137.623 125.438H189.377C192.327 125.438 194.719 123.046 194.719 120.096V68.3416C194.719 65.3915 192.327 63 189.377 63Z"
        fill="#161718"
        stroke="#A2A4A8"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M58.3772 63H6.62258C3.67251 63 1.28101 65.3915 1.28101 68.3416V120.096C1.28101 123.046 3.67251 125.438 6.62258 125.438H58.3772C61.3273 125.438 63.7188 123.046 63.7188 120.096V68.3416C63.7188 65.3915 61.3273 63 58.3772 63Z"
        fill="#161718"
        stroke="#A2A4A8"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M64.281 94H78.281"
        stroke="#A2A4A8"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeDasharray="5 5"
      />
      <path
        d="M118.281 94H132.281"
        stroke="#A2A4A8"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeDasharray="5 5"
      />
      <path
        d="M84.0629 91.4211C85.4581 83.8484 92.7321 78.838 100.305 80.2331C107.877 81.6282 112.888 88.9023 111.493 96.475C110.098 104.048 102.824 109.058 95.2509 107.663C87.6783 106.268 82.6678 98.9938 84.0629 91.4211Z"
        fill="#A2A4A8"
      />
      <path
        d="M104.349 92.0571H90.9412V101.139H104.349V92.0571Z"
        fill="#161718"
      />
      <path
        d="M94.9313 92.095V88.5013C94.9313 87.2419 95.9627 86.2105 97.2221 86.2105H98.0689C99.3283 86.2105 100.36 87.2419 100.36 88.5013V92.095H101.646V88.371C101.646 86.5145 100.132 85 98.2752 85H97.0266C95.1701 85 93.6501 86.5145 93.6501 88.371V92.095"
        fill="#161718"
      />
      <path
        d="M136.529 167.924H60.9601C59.8418 167.924 58.9353 168.831 58.9353 169.949V181.311C58.9353 182.429 59.8418 183.336 60.9601 183.336H136.529C137.648 183.336 138.554 182.429 138.554 181.311V169.949C138.554 168.831 137.648 167.924 136.529 167.924Z"
        stroke="inherit"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M44.9971 86.5619L32.6391 81L20.281 86.5619V102.305L32.6391 107.854L44.9971 102.305V86.5619Z"
        fill="#A2A4A8"
      />
      <path
        d="M180.811 94.0577L163.549 83L146.281 94.0577L163.549 105.115L180.811 94.0577Z"
        fill="#A2A4A8"
      />
    </svg>
  );
}

export { ConnectionsIllustration };
