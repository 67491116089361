export const ACCESS_MANAGEMENT_PATH = '/access-management';
export const API_DOCUMENTATION_PATH = ACCESS_MANAGEMENT_PATH + '/documentation';

export const SETTLEMENTS_PATH = '/atlas/';
export const SETTLEMENTS_LIST_PATH = '/atlas/list/';
export const SETTLEMENTS_PARTICIPANTS_PATH = '/atlas/participants/';
export const SETTLEMENTS_COUNTERPARTIES_PATH = '/atlas/counterparties/';

export const WEBHOOKS_PATH = '/webhooks';
export const API_V2_PATH = '/apis';
export const API_V2_CREATE_KEY_PATH = `${API_V2_PATH}/create-key`;
export const API_V2_VIEW_KEY_PATH = `${API_V2_PATH}/view-key/:key_id`;
export const API_V2_CREATE_ROLE_PATH = `${API_V2_PATH}/create-role`;
export const API_V2_EDIT_ROLE_PATH = `${API_V2_PATH}/edit-role/:role_id`;
export const API_V2_REFERENCE_IDS = `${API_V2_PATH}/reference-ids`;
export const API_V2_INTERNAL_TRANSFERS = `${API_V2_PATH}/shared-keys`;
export const API_V2_INTERNAL_TRANSFER_ENROLL = `${API_V2_INTERNAL_TRANSFERS}/enroll`;
export const API_V2_INTERNAL_TRANSFER_ENROLL_SETTLEMENTS_KEY = `${API_V2_INTERNAL_TRANSFER_ENROLL}?purpose=SETTLEMENT_NETWORK`;

export const DASHBOARD_PATH = `/dashboard`;
export const DEFAULT_PATH = '/';
export const LOGIN_PATH = `/login`;
export const STATEMENTS_PATH = `/statements`;

// BALANCES
export const DASHBOARD_BALANCES = `${DASHBOARD_PATH}/balances`;

// EMAIL
export const EMAIL_VERIFICATION_PATH = '/verify-email';
export const FRONTOFFICE_EMAIL_VERIFICATION_PATH = `${EMAIL_VERIFICATION_PATH}/`;

// SIGNUP
const SIGNUP_PATH = `/signup`;
const FRONTOFFICE_SIGNUP_PATH = `${SIGNUP_PATH}/`;

// ACTIVITY
export const ACTIVITY_PATH = `/activity`;
export const ACTIVITY_PENDING_PATH = `${ACTIVITY_PATH}/pending`;
export const ACTIVITY_PAST_PATH = `${ACTIVITY_PATH}/past`;
export const ACTIVITY_DEPOSIT_ATTRIBUTIONS_PATH = `${ACTIVITY_PATH}/deposit-attributions`;

// STAKE
export const STAKE_PATH = `/stake`;

// PORTFOLIO
export const PORTFOLIO_PATH = `${DASHBOARD_PATH}/portfolio`;

// VAULT
export const VAULT_PATH = '/vault';
export const VAULT_TAB_PATH = `${VAULT_PATH}/:vault_id`;
export enum VAULT_TABS {
  portfolio = 'portfolio',
  addresses = 'addresses',
  activity = 'activity',
  holds = 'holds',
  details = 'details',
}

export const VAULT_DETAILS_INDIVIDUAL_NFT_PATH = `${VAULT_TAB_PATH}/nfts/:asset_type_id/:token_id`;
export const VAULT_DETAILS_COLLECTION_NFT_PATH = `${VAULT_TAB_PATH}/nfts/:asset_type_id`;
export const VAULT_PORTFOLIO_PATH = `${VAULT_TAB_PATH}/${VAULT_TABS.portfolio}`;
export const VAULT_ADDRESSES_PATH = `${VAULT_TAB_PATH}/${VAULT_TABS.addresses}`;
export const VAULT_HOLDS_PATH = `${VAULT_TAB_PATH}/${VAULT_TABS.holds}`;
export const VAULT_DETAILS_PATH = `${VAULT_TAB_PATH}/${VAULT_TABS.details}`;
export const VAULT_PORTFOLIO_NFTS_PATH = `${VAULT_PORTFOLIO_PATH}/nfts`;

export const VAULT_DETAILS_ADDRESSES_QUERY_PARAMS = {
  ADDRESS: 'searchByAddress',
  ASSET: 'filterByAssetTypeIDs',
  WALLET: 'filterByWalletIDs',
};

export const OPERATION_QUERY_STRING = 'op_id';

// SETTINGS
export const TRUSTED_DESTINATION_QUERY_STRING = 'destinationID';
export const SETTINGS_PATH = '/settings';
export const DOCUMENT_DELIVERY_PATH = `${SETTINGS_PATH}/document-delivery`;
export const TRUSTED_SOURCES_PATH = `${SETTINGS_PATH}/trusted-sources`;
export const TRUSTED_DESTINATIONS_PATH = `${SETTINGS_PATH}/trusted-destinations`;
export const ADMINISTRATOR_POLICIES_PATH = `${SETTINGS_PATH}/policies`;
const ACCESS_AND_POLICIES_PATH = `${SETTINGS_PATH}/access-and-policies`;
export const ACCESS_AND_POLICIES_POLICIES_PATH = `${ACCESS_AND_POLICIES_PATH}/policies`;
export const ACCESS_AND_POLICIES_ROLES_PATH = `${ACCESS_AND_POLICIES_PATH}/roles`;

// SETTINGS WALLET KEYS - Aurora only
const WALLET_KEYS_PATH = `${SETTINGS_PATH}/wallet-keys`;
export const WALLET_KEYS_LIBRARY_PATH = `${WALLET_KEYS_PATH}/library`;
export const WALLET_KEYS_PREFERENCES_PATH = `${WALLET_KEYS_PATH}/preferences`;

// LEGAL DISCLOSURES
export const LEGAL_DISCLOSURE_ID_QUERY_STRING = 'disclosureId';

export const LEGAL_DISCLOSURES_PATH = `/legal-and-policies`;

export const getDisclosurePath = (disclosureId: string) => {
  return `${LEGAL_DISCLOSURES_PATH}/?${LEGAL_DISCLOSURE_ID_QUERY_STRING}=${disclosureId}`;
};

// IDENTITY & ACCESS MANAGEMENT
const IAM_PATH = `${SETTINGS_PATH}/iam`;
export const IAM_ROLES_PATH = `${IAM_PATH}/roles`;
export const IAM_ROLE_PATH = `${IAM_PATH}/role`;
export const IAM_ROLE_DETAIL_PATH = `${IAM_ROLE_PATH}/:role_id`;
export const IAM_ROLE_DETAIL_ASSIGNMENTS_PATH = `${IAM_ROLE_DETAIL_PATH}/assignments`;
export const IAM_ROLES_CUSTOM_PATH = `${IAM_ROLES_PATH}/custom`;

// CONNECTED APPS
export const CONNECTED_APPS_PATH = `/connected-apps`;
export const CONNECTED_APPS_ALLOW_LIST_PATH = `${CONNECTED_APPS_PATH}/allowlist`;
export const CONNECTED_APPS_TRUSTED_SMART_CONTRACTS_PATH = `${CONNECTED_APPS_PATH}/trusted-smart-contracts`;

// WEB 3 PORTAL
export const WEB3_PORTAL_PATH = `/web3-portal`;

export const TRANSFER_REQUEST_PURPOSE_QUERY = 'purpose';

// TRADE DASHBOARD
export const TRADE_PATH = `/trade`;
export const TRADE_HISTORY_PATH = `${TRADE_PATH}/history`;
export const TRADE_SETTLEMENT_PATH = `${TRADE_PATH}/settlement`;

// REPORTS
export const REPORTS_PATH = '/reports';
export const REPORTS_AVAILABLE = `${REPORTS_PATH}/available`;
export const REPORTS_LEGACY = `${REPORTS_PATH}/legacy`;

// MOBILE
export const ALLOWED_ROUTES_FOR_MOBILE = [
  FRONTOFFICE_EMAIL_VERIFICATION_PATH,
  FRONTOFFICE_SIGNUP_PATH,
];

// REST API routes
export const WALLET_KEY_BACKUP_ROUTE = '/v1/wallet_key_backup?wallet_key_id=';
export const VERIFY_WALLET_KEY_EXPORT_EMAIL_ROUTE =
  '/v1/verify_wallet_key_export_email?email_token=';

export const API_DOCUMENTATION_URL_V2 = '/v1/docs?path=/reference';
