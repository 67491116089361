const PaperPlaneIllustration = (props: SVGProps): JSX.Element => {
  const { title = 'PaperPlane', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 98 63"
      {...restProps}
      fill="none"
    >
      <title>{title}</title>
      <path fill="inherit" d="M13.856 20.661 59.12 62.58 97.213.42z" />
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.219"
        d="M96.995.532 31.708 36.915v23.27l14.344-9.561M.788 54.132 23.26 41.536M10.67 57.957l12.752-7.172M19.753 60.98l4.939-2.705"
      />
    </svg>
  );
};

export { PaperPlaneIllustration };
