import { useCallback } from 'react';

import { useToasterContext } from 'components/ToasterProvider';

import { ToastProps } from '../types';

import { ACTION_TYPES } from './useToastReducer';

/**
 * The count variable represents the id for the next toast.
 */
let count = 0;

/**
 * Represents a toast message.
 */
type Toast = Omit<ToastProps, 'id'>;

/**
 * Generates a unique ID for the toast.
 * @returns {string} The generated ID.
 */
function genId() {
  count = (count + 1) % Number.MAX_SAFE_INTEGER;
  return count.toString();
}

/**
 * Custom hook for managing toasts.
 * @returns An object containing the `toast` function and the `dismiss` function.
 */
const useToast = () => {
  const { dispatch } = useToasterContext();

  const toast = useCallback(
    ({ ...props }: Toast) => {
      const id = genId();

      const dismiss = () =>
        dispatch({ type: ACTION_TYPES.DISMISS_TOAST, toastId: id, dispatch });

      dispatch({
        type: ACTION_TYPES.ADD_TOAST,
        toast: {
          ...props,
          id,
          open: true,
          onOpenChange: (open) => {
            if (!open) {
              dismiss();
            }
          },
        },
      });

      return {
        id: id,
        dismiss,
      };
    },
    [dispatch],
  );

  const dismiss = useCallback(
    (toastId?: string) =>
      dispatch({ type: ACTION_TYPES.DISMISS_TOAST, toastId, dispatch }),
    [dispatch],
  );

  return {
    toast,
    dismiss,
  };
};

export { useToast };
