const PORTO_DOMAIN = 'porto.xyz';

const isPorto = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const { location } = window;

  // hostname examples:
  // - port3000.YOUR_URSERNAME.anchorlabs.dev
  const { hostname } = location;

  if (hostname.endsWith(PORTO_DOMAIN)) {
    return true;
  }

  return false;
};

const useIsPortoApp = () => {
  const isPortoDomain = isPorto();

  // Env var should be set in source/js/anchorage/apps/frontoffice/.env.local
  const hasEnvVarOverride = process
    ? process.env.NEXT_PUBLIC_PORTO_OVERRIDE === 'true'
    : false;

  return isPortoDomain || hasEnvVarOverride || false;
};

export default useIsPortoApp;
