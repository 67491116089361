export const WarningIllustration = (props: SVGProps): JSX.Element => {
  const { title = 'WarningIllustration', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 218 167"
      {...restProps}
      fill="none"
    >
      <title>{title}</title>
      <path
        d="M55.5199 112.047C62.1039 106.96 66.2971 101.355 64.8857 99.5282C63.4743 97.7014 56.9927 100.344 50.4087 105.431C43.8247 110.518 39.6315 116.123 41.0429 117.949C42.4543 119.776 48.9359 117.133 55.5199 112.047Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M8.12252 85.5702C12.1487 85.5702 15.4125 82.3064 15.4125 78.2802C15.4125 74.2541 12.1487 70.9902 8.12252 70.9902C4.09636 70.9902 0.83252 74.2541 0.83252 78.2802C0.83252 82.3064 4.09636 85.5702 8.12252 85.5702Z"
        fill="#5681F6"
      />
      <path
        d="M206.837 98.9398C212.542 98.9398 217.167 94.3149 217.167 88.6098C217.167 82.9047 212.542 78.2798 206.837 78.2798C201.132 78.2798 196.507 82.9047 196.507 88.6098C196.507 94.3149 201.132 98.9398 206.837 98.9398Z"
        fill="#5681F6"
      />
      <path
        d="M34.4976 49.84C40.8738 49.84 46.0426 44.6711 46.0426 38.295C46.0426 31.9189 40.8738 26.75 34.4976 26.75C28.1215 26.75 22.9526 31.9189 22.9526 38.295C22.9526 44.6711 28.1215 49.84 34.4976 49.84Z"
        fill="#5681F6"
      />
      <path
        d="M43.8374 30.8798C43.8374 30.8798 52.6174 27.1498 52.7324 28.4698C53.1424 33.1798 38.3674 45.3848 21.9074 48.1548C5.4474 50.9248 23.4574 41.4398 23.4574 41.4398"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M46.2327 158.495C52.2443 158.495 57.1177 153.622 57.1177 147.61C57.1177 141.598 52.2443 136.725 46.2327 136.725C40.221 136.725 35.3477 141.598 35.3477 147.61C35.3477 153.622 40.221 158.495 46.2327 158.495Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M141.472 143C145.264 143 148.337 139.926 148.337 136.135C148.337 132.344 145.264 129.27 141.472 129.27C137.681 129.27 134.607 132.344 134.607 136.135C134.607 139.926 137.681 143 141.472 143Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M143.079 116.926C144.82 109.489 134.005 100.597 118.921 97.0649C103.838 93.5326 90.1982 96.698 88.4566 104.135C86.715 111.572 97.5308 120.464 112.614 123.996C127.698 127.529 141.337 124.363 143.079 116.926Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M23.4474 166.45C23.4024 165.38 23.3774 164.305 23.3774 163.225C23.3774 121.565 57.1474 87.7949 98.8074 87.7949C135.257 87.7949 165.672 113.65 172.707 148.025C173.712 152.935 174.242 158.02 174.242 163.225"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M214.648 42.6651C214.648 42.6651 214.648 34.7301 206.168 36.1701C197.688 37.6151 194.983 38.1551 194.803 34.1851C194.623 30.2151 191.193 26.2501 183.258 28.5951C175.323 30.9401 167.023 34.1851 164.858 32.3851C162.693 30.5801 161.248 30.4001 162.873 24.8101C164.498 19.2201 155.478 16.1501 148.623 19.2201C141.768 22.2851 120.483 35.2751 111.103 42.4901C111.103 42.4901 214.648 41.5901 214.648 42.6701V42.6651Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M62.0223 62.245L101.802 1.68C103.272 -0.56 106.557 -0.56 108.027 1.68L147.807 62.245C149.432 64.72 147.657 68.015 144.692 68.015H65.1273C62.1623 68.015 60.3873 64.725 62.0123 62.245H62.0223Z"
        fill="white"
      />
      <path
        d="M104.917 57.7299C106.792 57.7299 108.312 56.2099 108.312 54.3349C108.312 52.4599 106.792 50.9399 104.917 50.9399C103.042 50.9399 101.522 52.4599 101.522 54.3349C101.522 56.2099 103.042 57.7299 104.917 57.7299Z"
        fill="black"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M105.143 21.25H105.138C103.553 21.25 102.268 22.5349 102.268 24.12V41.205C102.268 42.7901 103.553 44.075 105.138 44.075H105.143C106.728 44.075 108.013 42.7901 108.013 41.205V24.12C108.013 22.5349 106.728 21.25 105.143 21.25Z"
        fill="black"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
