const SubtractIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Subtract', className, ...restProps } = props;

  return (
    <svg
      className={className}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 26H10V22H38V26Z" />
    </svg>
  );
};

export { SubtractIcon };
