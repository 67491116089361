import { cva } from 'class-variance-authority';

export const signalVariants = cva(
  [
    'w-sizing-comp-signal',
    'h-sizing-comp-signal',
    'rounded-radius-sys-radius-8',
    'border-color-ref-ui-background-base',
    'box-content',
  ],
  {
    variants: {
      status: {
        default: ['bg-color-comp-signal-neutral'],
        danger: ['bg-color-comp-signal-danger'],
        warning: ['bg-color-comp-signal-warning'],
        success: ['bg-color-comp-signal-success'],
        informational: ['bg-color-comp-signal-info'],
      },
      border: {
        true: ['border-border-sys-border-2'],
        false: ['border-0'],
      },
    },
    defaultVariants: {
      status: 'default',
      border: false,
    },
  },
);
